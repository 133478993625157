import { readState } from "@/__main__/app-state.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import appRefs from "@/app/refs.mjs";
import {
  EVENT_USER_LOGOUT,
  initAuth,
} from "@/feature-auth/utils/auth-actions.mjs";
import { AccountMenuWalletItem } from "@/feature-wallet/AccountMenuWalletItem.jsx";
import {
  clearUserData,
  initWalletState,
  removeWalletState,
} from "@/feature-wallet/actions.mjs";
import devHelpers from "@/feature-wallet/dev-helpers.mjs";
import type { BaseRoute } from "@/routes/routes.mjs";
import routes, { appRoutes } from "@/routes/routes.mjs";
import globals from "@/util/global-whitelist.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import pathRegExp from "@/util/path-regexp.mjs";

const walletTabs = ["portfolio", "transactions"];

const fetchWalletData = lazyFn(
  () => import("@/feature-wallet/fetches/user-wallet.mjs"),
);

const allWalletRoutes: BaseRoute[] = [
  {
    path: "/wallet",
    redirect: `/wallet/portfolio`,
  },
  {
    path: pathRegExp(`/wallet/:panel`),
    component: "feature-wallet/Wallet.tsx",
    fetchData: fetchWalletData,
    redirect: async ({ parameters: [currentTab] }) => {
      await initAuth();

      if (!readState.user) {
        return "/account";
      }

      if (!walletTabs.includes(currentTab)) {
        return "/wallet/portfolio";
      }

      return null;
    },
  },
];

const originals = mapOriginalRefs({
  appRefsAccountMenuItems: appRefs.accountMenuItems,
});

export function setup() {
  initWalletState();
  eventBus.on(EVENT_USER_LOGOUT, clearUserData);

  appRoutes.push(...allWalletRoutes);
  routes.push(...allWalletRoutes);

  originals.set({
    appRefsAccountMenuItems: {
      slotTwo: AccountMenuWalletItem,
    },
  });

  // Dev
  globals.__BLITZ_DEV__.wallet = devHelpers;
}

export function teardown() {
  originals.restore();

  for (const route of allWalletRoutes) {
    appRoutes.splice(appRoutes.indexOf(route), 1);
    routes.splice(routes.indexOf(route), 1);
  }

  eventBus.off(EVENT_USER_LOGOUT, clearUserData);
  removeWalletState();
}
