import React from "react";

import { accountRefs } from "@/app/account.refs.mjs";
import MenuListItem from "@/app/AppShellMenuListItem.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function AccountMenuWalletItem({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  const { isLoggedIn } = useSnapshot(accountRefs);

  return (
    isLoggedIn && (
      <MenuListItem
        text={["common:wallet.wallet", "Wallet"]}
        href="/wallet"
        onClick={onClick}
        {...classNames(className)}
      />
    )
  );
}
