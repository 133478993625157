import { readState } from "@/__main__/app-state.mjs";
import getData, { postData } from "@/__main__/get-data.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import noopModel from "@/data-models/no-op.mjs";
import { devError } from "@/util/dev.mjs";

// TestClocks auto delete after 30 days. Check here https://dashboard.stripe.com/test/test-clocks
// This key is TEST ONLY. Currently it ONLY has permission to create test clocks.
const apiKey =
  "rk_test_51GaADOEE4xt84LKu4dOL1v0hmjmv9aRSe1HBzynWAagGH8Ymb7MuoZO3YaLImVogMEPWZ165NigTzv3V91zFA3C3007Pv5qPwE";
const baseUri = "https://api.stripe.com/v1/test_helpers/test_clocks";

const convertDate = (date?: string) =>
  Math.floor((date ? new Date(date).getTime() : Date.now()) / 1000).toString();

const aDay = 24 * 60 * 60 * 1000;
export default {
  get testClock() {
    return { ...readState.volatile.testClock };
  },
  set testClock(clock) {
    setVolatileKV("testClock", clock);
  },
  async createTestClock(startDate) {
    const user = readState.user;
    if (!user?.email) {
      devError("No user email found");
      return;
    }

    const clock = await postData(
      {
        url: baseUri,
        body: new URLSearchParams({
          name: user.email,
          frozen_time: convertDate(startDate),
        }),
      },
      noopModel,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "content-type": "application/x-www-form-urlencoded",
        },
      },
    );

    this.testClock = clock;
  },
  async fetchUserTestClock() {
    const user = readState.user;
    if (!user?.email) {
      devError("No user email found");
      return;
    }

    const clock = (
      await getData(baseUri, noopModel, undefined, {
        headers: { Authorization: `Bearer ${apiKey}` },
      })
    ).data.find(({ name }) => name === user.email);

    if (!clock) {
      devError("No clock for user found");
      return;
    }

    this.testClock = clock;
  },
  async advanceClock(to) {
    if (
      !this.testClock ||
      !readState.user ||
      this.testClock.name !== readState.user.email
    ) {
      devError("No clock for user found");
      return;
    }

    const clock = await postData(
      {
        url: `${baseUri}/${this.testClock.id}/advance`,
        body: new URLSearchParams({
          frozen_time: convertDate(to),
        }),
      },
      noopModel,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "content-type": "application/x-www-form-urlencoded",
        },
      },
    );

    this.testClock = clock;
  },
  async advanceClockByDay(amt = 1) {
    if (
      !this.testClock ||
      !readState.user ||
      this.testClock.name !== readState.user.email
    ) {
      devError("No clock for user found");
      return;
    }

    await this.advanceClock(this.testClock.frozen_time * 1000 + amt * aDay);
  },
  async advanceClockByMonth(amt = 1) {
    await this.advanceClockByDay(30 * amt);
  },
};
